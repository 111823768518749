import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { Container } from "../components/utils"
// import NewsList from "../components/newsList"
// import FeaturedNews from "../components/featuredNews"
import Pagination from "react-js-pagination"
import DesignTokens from "../components/designTokens"
import ArticlesList from "../components/articlesList"
import Intro from "../components/intro"

const ArticlesListPage = ({ data: { page }, pageContext }) => {
  const getLink = pageNumber => {
    if (pageNumber === 1 || pageNumber === 0) {
      return `/news/`
    } else {
      return `/news/${pageNumber}/`
    }
  }
  const handleChange = pageNumber => {
    if (pageNumber === 1) {
      navigate(`/news/`)
    } else {
      navigate(`/news/${pageNumber}/`)
    }
  }

  return (
    <Layout>
      <SEO title={pageContext.title} />
      <Header>
        <h1>News</h1>
        <h2>Le ultime notizie da LTE Impianti</h2>
      </Header>
      <Intro title="Le ultime notizie" />
      <Container>
        <ArticlesList articles={page.nodes} />
      </Container>
      <CustomContainer>
        <Pagination
          hideDisabled
          hideNavigation
          activePage={pageContext.currentPage}
          itemsCountPerPage={pageContext.limit}
          totalItemsCount={pageContext.numStoriesPages * pageContext.limit}
          pageRangeDisplayed={5}
          onChange={handleChange}
          getPageUrl={getLink}
        />
      </CustomContainer>
    </Layout>
  )
}

export default ArticlesListPage

const CustomContainer = styled(Container)`
  .pagination {
    margin: 0 0 3rem;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > li {
      margin: 0 1rem;
      &.active {
        a {
          text-decoration: none;
          color: ${DesignTokens.colors.black};
          cursor: auto;
        }
      }
    }
  }
`

export const query = graphql`
  query ArticlesListQuery($skip: Int!, $limit: Int!) {
    page: allDatoCmsArticle(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        id
        title
        slug
        text
        meta {
          firstPublishedAt
        }
        image {
          sizes(imgixParams: { fit: "crop", w: "675", h: "380" }) {
            base64
            width
            height
            src
            srcSet
            sizes
          }
          url
          alt
        }
      }
    }
  }
`
