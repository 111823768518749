import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
// import referencePlaceholder from "../images/reference-placeholder.png"
import { ItemsList } from "./utils"
// import Label from "./label"
// import ReferenceDetails from "./referenceDetails"

const ArticlesList = ({ articles }) => (
  <CustomItemsList>
    {articles.map(article => (
      <li key={article.id}>
        <Article article={article} />
      </li>
    ))}
  </CustomItemsList>
)

const CustomItemsList = styled(ItemsList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ${DesignTokens.spacing[8]};

  & > li {
    width: 100%;
    margin: 0 0 ${DesignTokens.spacing[4]};
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (min-width: 769px) {
      margin: 2% 2% 0 0;
      width: calc(98% / 2);
      &:nth-child(-n + 2) {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1025px) {
      width: calc(96% / 3);
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      &:nth-child(even) {
        margin-right: 2%;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
`

const Article = ({ article }) => (
  <StyledArticle to={`/news/` + article.slug}>
    <img src={article.image.sizes.src} alt={article.image.alt} />
    <Published>
      {new Intl.DateTimeFormat("it", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(new Date(article.meta.firstPublishedAt))}
    </Published>
    <h4>{article.title}</h4>
    <FakeLink>Scopri di più +</FakeLink>
  </StyledArticle>
)

const Published = styled.p`
  font-size: ${DesignTokens.fontSizes[3]};
  color: ${DesignTokens.colors.secondary[500]};
  font-weight: bold;
  text-transform: uppercase;
`

const StyledArticle = styled(Link)`
  color: ${props =>
    props.theme === "dark"
      ? DesignTokens.colors.white
      : DesignTokens.colors.black};
  text-decoration: none;
  display: block;
  img {
    transition: all 0.2s ease-out;
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  h4 {
    transition: all 0.2s ease-in;
    font-weight: 400;
    font-size: ${DesignTokens.fontSizes[6]};
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  ul {
    margin: 0 0 ${DesignTokens.spacing[6]};
    padding: 0;
    list-style-type: none;
    li {
      margin: ${DesignTokens.spacing[1]} 0;
      line-height: 1.2;
    }
  }
  a {
    color: ${DesignTokens.colors.secondary[500]};
    text-decoration: none;
  }
  &:hover {
    img {
      transform: scale(1.025);
    }
    h4 {
      color: ${DesignTokens.colors.secondary[500]};
    }
  }
`

const FakeLink = styled.p`
  color: ${DesignTokens.colors.secondary[500]};
`

export default ArticlesList
